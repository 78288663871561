/* Notification additional styles */
.Toastify__toast-container {
  margin: 10px 100px 0 0;
  z-index: 10000001;
  position: absolute;
}

.Toastify__toast {
  width: 400px;
}
