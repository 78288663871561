.layout-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.layout-container-content {
  display: flex;
  flex: 1;
}

.layout-container-content-list {
    flex: 1 1 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
}
