.header span {
  color: white;
}

.header {
  display: flex;
  background-color: black;
  height: 100px;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
}

.header-provider:hover {
    cursor: pointer;
}

.user {
  padding: 20px;
  border-radius: 5px;
}

.user:hover {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
}

.user:hover > * {
  color: black;
}
