.loading-container {
  align-self: center;
  height: 77vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#fabric-list-container {
    display: flex;

    .root-204 {
        flex:1;
    }
}
